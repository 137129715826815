import React from 'react';
import { Mail, Twitter, Instagram } from 'lucide-react';

export default function Footer() {
  return (
    <footer className="border-t border-gray-800 mt-auto">
      <div className="container mx-auto px-4 py-4">
        <div className="flex flex-col gap-3">
          {/* More Tools Row - Center Aligned */}
          <div className="flex justify-center items-center mb-2">
            <div className="text-gray-400">
              <a
                href="https://webuildstuff.io"
                target="_blank"
                rel="dofollow index"
                className="font-bold mr-2 text-gray-400 hover:text-purple-400 transition-colors duration-200"
              >
                More tools:
              </a>
              <a
                href="https://howmuchwaterdoineed.com"
                target="_blank"
                rel="dofollow index"
                className="text-gray-400 hover:text-purple-400 transition-colors duration-200 mx-2"
              >
                💧 How much water do I need?
              </a>
              <a
                href="https://powerloss.tech"
                target="_blank"
                rel="dofollow index"
                className="text-gray-400 hover:text-purple-400 transition-colors duration-200 mx-2"
              >
                🔋 PowerLoss.tech
              </a>
              <a
                href="https://upgrade.tools"
                target="_blank"
                rel="dofollow index"
                className="text-gray-400 hover:text-purple-400 transition-colors duration-200 mx-2"
              >
                ⤴️ Upgrade.tools
              </a>
              <a
                href="https://droneflightcalc.com"
                target="_blank"
                rel="dofollow index"
                className="text-gray-400 hover:text-purple-400 transition-colors duration-200 mx-2"
              >
                ✈️ Drone Flight Calc
              </a>
              <a
                href="https://emf.exposed"
                target="_blank"
                rel="dofollow index"
                className="text-gray-400 hover:text-purple-400 transition-colors duration-200 mx-2"
              >
                ☢️ EMF.exposed
              </a>
            </div>
          </div>

          <div className="flex flex-col sm:flex-row justify-between items-center gap-4">
            {/* Social Media Icons - Left Aligned */}
            <div className="flex items-center gap-3">
              <a
                href="mailto:help@playlist.rest"
                className="text-gray-400 hover:text-purple-400 transition-colors"
                aria-label="Email us"
              >
                <Mail className="w-5 h-5" />
              </a>
              <a
                href="https://twitter.com/nichm"
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-400 hover:text-purple-400 transition-colors"
                aria-label="Follow us on Twitter"
              >
                <Twitter className="w-5 h-5" />
              </a>
              <a
                href="https://instagram.com"
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-400 hover:text-purple-400 transition-colors"
                aria-label="Follow us on Instagram"
              >
                <Instagram className="w-5 h-5" />
              </a>
              <a
                href="https://tiktok.com"
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-400 hover:text-purple-400 transition-colors"
                aria-label="Follow us on TikTok"
              >
                <svg 
                  className="w-5 h-5" 
                  viewBox="0 0 24 24" 
                  fill="none" 
                  stroke="currentColor" 
                  strokeWidth="2" 
                  strokeLinecap="round" 
                  strokeLinejoin="round"
                >
                  <path d="M9 12a4 4 0 1 0 4 4V4a5 5 0 0 0 5 5" />
                </svg>
              </a>
            </div>

            {/* Copyright - Right Aligned */}
            <div className="text-gray-400 text-sm">
              playlist.rest © {new Date().getFullYear()} - Built with ♥ by{' '}
              <a
                href="https://webuildstuff.io"
                target="_blank"
                rel="noopener noreferrer"
                className="text-purple-400 hover:text-purple-300 transition-colors duration-200"
              >
                webuildstuff.io
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}